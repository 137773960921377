<template>
    <div>
        <div class="main-content">
            <div class="content">
                <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px" height="1%">
                    <el-table-column prop="nickname" label="用户昵称" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.nickname ? scope.row.nickname : '/' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="性别" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.gender == 1 ? '男' : '女' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="city" label="城市" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.city ? scope.row.city : '/' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="电话" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.mobile ? scope.row.mobile : '/' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="integral" label="积分值" align="center"></el-table-column>
                    <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
                    <el-table-column prop="status" label="状态" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.status == 1 ? '正常' : '禁用' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-link v-if="scope.row.status == 1" type="danger" :underline="false"
                                     @click="delBtn(scope.row)">禁用
                            </el-link>
                            <el-link v-else type="danger" :underline="false" @click="delBtn(scope.row)">启用</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pages-center"
                               :current-page="listPages.currentPageNum"
                               :page-size="listPages.eachPageNum"
                               layout="prev, pager, next, jumper"
                               :total="listPages.total"
                               @current-change="pageCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {account_courier_index, account_courier_edit} from '@/config/apis'

    export default {
        data() {
            return {
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    limit: this.listPages.eachPageNum,
                    page: this.listPages.currentPageNum,
                }
                account_courier_index(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.infoList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            delBtn(row) {
                let params = {
                    id: row.id,
                    status: row.status == 1 ? '0' : '1'
                }
                account_courier_edit(params).then((res) => {
                    this.$message.success(res.msg)
                    this.getList();
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
</style>